#process li {
  padding-top: 90px;
}

.steps-list {
  width: calc(8 * (var(--column-size)) - var(--column-gutter));
}

.steps-list > li:nth-child(even) .step {
  flex-direction: row-reverse;
}

.step {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.step-image {
  display: flex;
  flex-direction: column;
}

.steps-list > li:nth-child(even) .step img {
  align-self: flex-end;
}

.step-number {
  display: inline-block;
  font-size: 12px;
  line-height: 1.2em;
  color: #70777e;
  margin-bottom: 10px;
}

.step-name {
  margin-bottom: 24px;
}

.step-description {
  color: #70777e;
}

@media all and (max-width: 1279px) {
  #process li {
    padding-top: 80px;
  }
}

@media all and (max-width: 1023px) {
  #process li:first-child {
    padding-top: 40px;
  }
}

@media all and (max-width: 767px) {
  #process li {
    padding-top: 50px;
  }

  .step-name {
    margin-bottom: 16px;
  }

  .steps-list {
    width: 100%;
  }

  .step {
    flex-direction: column;
    justify-content: center;
    gap: 24px;
  }

  .steps-list > li:nth-child(even) .step {
    flex-direction: column;
  }

  .step-image {
    align-items: center;
  }

  .step-image img {
    width: calc(2 * var(--column-size));
  }

  .steps-list > li:nth-child(even) .step img {
    align-self: inherit;
}
}
