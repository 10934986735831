/* Section "Services" */
picture {
  position: static;
}

#services {
  padding-bottom: 150px;
}

#services .container {
  position: relative;
}

#services h4 {
  padding-bottom: 60px;
  text-align: center;
}

.services-group {
  display: flex;
  align-items: center;
}

.service {
  position: relative;
  width: 450px;
  height: 450px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.service img {
  position: absolute;
  top: 0;
}

.design img {
  left: 22px;
  transform: rotate(-35deg);
}

.development img {
  right: 22px;
  transform: rotate(-117deg) scaleX(-1);
}

.service h5 {
  color: #1f1f1f;
  text-align: center;
  padding-bottom: 12px;
}

.service p {
  font-weight: normal;
  font-size: 16px;
  color: #70777e;
  text-align: center;
  max-width: 230px;
}

.design .block {
  margin-left: 40px;
}

.development .block {
  margin-right: 40px;
}

.button-touch {
  width: 180px;
  height: 180px;
  right: 65px;
  bottom: 20px;
}

@media all and (max-width: 1279px) {
  h2 {
    font-size: 80px;
  }

  #services {
    padding-bottom: 120px;
  }

  .button-touch {
    right: 0;
  }

  .service {
    width: 414px;
    height: 414px;
  }
}

@media all and (max-width: 1023px) {
  h2 {
    line-height: 1.1em;
  }

  h5 {
    font-size: 18px;
    line-height: 1.2em;
  }

  #services {
    padding-bottom: 90px;
  }

  #services h4 {
    padding-bottom: 50px;
  }

  .button-touch {
    right: 50px;
    bottom: 30px;
  }

  .services-group {
    flex-direction: column;
    gap: 40px;
  }

  .service {
    width: 450px;
    height: 450px;
  }

  .design img {
    transform: rotate(-30deg);
    left: 0;
  }

  .design .block {
    margin-left: 0;
  }

  .development img {
    right: 0;
  }

  .development .block {
    margin-right: 0;
  }
}

@media all and (max-width: 767px) {
  h2 {
    font-size: 48px;
  }

  h4 {
    font-size: 24px;
  }

  h5 {
    font-size: 18px;
  }

  #services {
    padding: 60px 0;
  }

  .service {
    width: 278px;
    height: 278px;
  }

  .design img {
    transform: rotate(0);
  }

  .development img {
    transform: rotate(90deg);
  }

  .button-touch {
    position: relative;
    right: initial;
    bottom: initial;
    margin-top: 40px;
  }
}
