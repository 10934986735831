/* Standart settings */

@font-face {
  font-family: "Kharkiv Tone";
  src: url("../../assets/fonts/Kharkiv-Tone.woff2") format("woff2");
}

:root {
  --columns-count: 12;
  --column-width: 60px;
  --column-gutter: 40px;
  --column-size: calc(var(--column-width) + var(--column-gutter));
  --container-width: calc(
    var(--columns-count) * var(--column-width) + (var(--columns-count) - 1) *
      var(--column-gutter)
  );
}

* {
  font-family: "Poppins", sans-serif;
  padding: 0;
  margin: 0;
  line-height: 1.5em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
  user-select: none;
}

html {
  scroll-behavior: smooth;
  min-width: 325px;
}

body.no-scroll {
  overflow-y: hidden;
}

section {
  padding: 160px 0;
}

a {
  text-decoration: none;
  color: inherit;
  font: inherit;
}

ul {
  list-style: none;
}

picture {
  display: flex;
  position: relative;
}

.container {
  width: var(--container-width);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Kharkiv Tone", sans-serif;
  font-weight: normal;
}

h1 {
  font-size: 110px;
  line-height: 1.3em;
}

h2 {
  font-size: 80px;
  line-height: 1.1em;
}

h3 {
  font-size: 64px;
  line-height: 1em;
}

h4 {
  font-size: 32px;
}

h5 {
  font-size: 20px;
  line-height: 1.2em;
}

h6 {
  font-size: 18px;
  line-height: 1.2em;
}

.p14 {
  font-size: 14px;
  color: #70777e;
}

.p16 {
  font-size: 16px;
}

.button-circle {
  position: absolute;
  background: linear-gradient(
    to right,
    #a5c4e9 0%,
    #d8c5d6 44.27%,
    #e3a273 93.75%
  );
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-circle p {
  font-weight: normal;
  font-size: 16px;
  color: #2c2c2c;
}

.background-dark {
  background: url("../../assets/images/backgrounds/dark.png");
}

.background-light {
  background: url("../../assets/images/backgrounds/light.png");
}

.ml-1,
.mr-1,
.pl-1,
.pr-1,
.col-1,
.wd-1 {
  --column-count: 1;
}

.ml-2,
.mr-2,
.pl-2,
.pr-2,
.col-2,
.wd-2 {
  --column-count: 2;
}

.ml-3,
.mr-3,
.pl-3,
.pr-3,
.col-3,
.wd-3 {
  --column-count: 3;
}

.ml-4,
.mr-4,
.pl-4,
.pr-4,
.col-4,
.wd-4 {
  --column-count: 4;
}

.ml-5,
.mr-5,
.pl-5,
.pr-5,
.col-5,
.wd-5 {
  --column-count: 5;
}

.ml-6,
.mr-6,
.pl-6,
.pr-6,
.col-6,
.wd-6 {
  --column-count: 6;
}

.ml-7,
.mr-7,
.pl-7,
.pr-7,
.col-7,
.wd-7 {
  --column-count: 7;
}

.ml-8,
.mr-8,
.pl-8,
.pr-8,
.col-8,
.wd-8 {
  --column-count: 8;
}

.ml-9,
.mr-9,
.pl-9,
.pr-9,
.col-9,
.wd-9 {
  --column-count: 9;
}

.ml-10,
.mr-10,
.pl-10,
.pr-10,
.col-10,
.wd-10 {
  --column-count: 10;
}

.ml-11,
.mr-11,
.pl-11,
.pr-11,
.col-11,
.wd-11 {
  --column-count: 11;
}

.ml-12,
.mr-12,
.pl-12,
.pr-12,
.col-12,
.wd-12 {
  --column-count: 12;
}

.ml-1,
.ml-2,
.ml-3,
.ml-4,
.ml-5,
.ml-6,
.ml-7,
.ml-8,
.ml-9,
.ml-10,
.ml-11,
.ml-12 {
  margin-left: calc(var(--column-size) * var(--column-count));
}

.mr-1,
.mr-2,
.mr-3,
.mr-4,
.mr-5,
.mr-6,
.mr-7,
.mr-8,
.mr-9,
.mr-10,
.mr-11,
.mr-12 {
  margin-right: calc(var(--column-size) * var(--column-count));
}

.pl-1,
.pl-2,
.pl-3,
.pl-4,
.pl-5,
.pl-6,
.pl-7,
.pl-8,
.pl-9,
.pl-10,
.pl-11,
.pl-12,
.sm-pl-1,
.sm-pl-2,
.sm-pl-3,
.sm-pl-4,
.sm-pl-5,
.sm-pl-6,
.sm-pl-7,
.sm-pl-8,
.xs-pl-1,
.xs-pl-2,
.xs-pl-3,
.xs-pl-4 {
  padding-left: calc(var(--column-size) * var(--column-count));
}

.pr-1,
.pr-2,
.pr-3,
.pr-4,
.pr-5,
.pr-6,
.pr-7,
.pr-8,
.pr-9,
.pr-10,
.pr-11,
.pr-12 {
  padding-right: calc(var(--column-width) * var(--column-count));
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  flex-basis: 0;
  flex-grow: var(--column-count);
}

.wd-1,
.wd-2,
.wd-3,
.wd-4,
.wd-5,
.wd-6,
.wd-7,
.wd-8,
.wd-9,
.wd-10,
.wd-11,
.wd-12,
.sm-wd-1,
.sm-wd-2,
.sm-wd-3,
.sm-wd-4,
.sm-wd-5,
.sm-wd-6,
.sm-wd-7,
.sm-wd-8,
.xs-wd-1,
.xs-wd-2,
.xs-wd-3,
.xs-wd-4 {
  width: calc(
    var(--column-count) * (var(--column-size)) - var(--column-gutter)
  );
}

.col {
  flex-basis: auto;
  flex-grow: 1;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.relative {
  position: relative;
}

img {
  width: 100%;
  object-fit: cover;
}

@media all and (min-width: 1920px) {
  :root {
    --column-width: 70px;
  }
}

@media all and (max-width: 1279px) {
  :root {
    --column-gutter: 20px;
  }

  h2 {
    font-size: 64px;
    line-height: 1.1em;
  }

  section {
    padding: 120px 0;
  }
}

@media all and (max-width: 1023px) {
  :root {
    --columns-count: 8;
    --column-gutter: 30px;
  }

  h2 {
    line-height: 1.3em;
  }

  h4 {
    font-size: 24px;
  }

  h5 {
    font-size: 14px;
    line-height: 1.5em;
  }

  section {
    padding: 90px 0;
  }

  .sm-wd-1,
  .sm-pl-1 {
    --column-count: 1;
  }

  .sm-wd-2,
  .sm-pl-2 {
    --column-count: 2;
  }

  .sm-wd-3,
  .sm-pl-3 {
    --column-count: 3;
  }

  .sm-wd-4,
  .sm-pl-4 {
    --column-count: 4;
  }

  .sm-wd-5,
  .sm-pl-5 {
    --column-count: 5;
  }

  .sm-wd-6,
  .sm-pl-6 {
    --column-count: 6;
  }
  
  .sm-wd-7,
  .sm-pl-7 {
    --column-count: 7;
  }

  .sm-wd-8,
  .sm-pl-8 {
    --column-count: 8;
  }
}

@media all and (max-width: 767px) {
  :root {
    --columns-count: 4;
    --column-gutter: 20px;
    --column-margin: 20px;
    --column-width: calc(
      (
          100vw - 2 * var(--column-margin) - (var(--columns-count) - 1) *
            var(--column-gutter)
        ) / var(--columns-count)
    );
  }

  section {
    padding: 60px 0;
  }

  h2 {
    font-size: 32px;
  }

  h4 {
    font-size: 20px;
  }

  h5 {
    font-size: 14px;
  }

  .xs-wd-1,
  .xs-pl-1 {
    --column-count: 1;
  }

  .xs-wd-2,
  .xs-pl-2 {
    --column-count: 2;
  }

  .xs-wd-3,
  .xs-pl-3 {
    --column-count: 3;
  }

  .xs-wd-4,
  .xs-pl-4 {
    --column-count: 4;
  }
}
