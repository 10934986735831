/* Section "Cases" */

#cases h4 {
  color: white;
  padding-bottom: 60px;
}

.cases-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 60px;
}

.cases-list li {
  position: relative;
  width: calc((4 * var(--column-size)) - var(--column-gutter));
  padding-top: 47.414%;
}

.card,
.more-cases {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  border-radius: 4px;
  overflow: hidden;
}

/* Gradient border */
.card::before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to right, #a5c4e9, #d8c5d6, #e3a273);
  -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"><rect x="0" y="0" width="100%" height="100%" rx="4" ry="4" stroke-width="1" fill="transparent" stroke="white"/></svg>')
    0 / 100% 100%;
  mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"><rect x="0" y="0" width="100%" height="100%" rx="4" ry="4" stroke-width="1" fill="transparent" stroke="white"/></svg>')
    0 / 100% 100%;
}

.card-text {
  padding-top: 40px;
  padding-left: 40px;
  padding-right: 40px;
}

.card-date {
  position: relative;
  font-weight: normal;
  font-size: 12px;
  color: #70777e;
  display: inline-block;
  padding-left: 12px;
  line-height: 1.2em;
}

.card-date::before {
  position: absolute;
  content: "";
  width: 4px;
  height: 4px;
  background: #afc5e6;
  border-radius: 50%;
  left: 0;
  top: calc(50% - 2px);
}

.card-title {
  color: #f2f2f2;
  padding-top: 8px;
}

.card-description {
  color: #70777e;
  padding-top: 16px;
}

.card-image {
  position: absolute;
  width: 77.778%;
  height: 65.455%;
  bottom: 0;
  right: 0;
  transition: all 0.3s ease-in-out;
}

.card:hover {
  cursor: pointer;
}

.card:hover .card-image {
  width: 100%;
  height: 100%;
}

.card-image img {
  height: 100%;
}

.more-cases {
  display: flex;
  justify-content: center;
  align-items: center;
}

.more-cases img {
  width: auto;
}

.more-cases > img {
  height: 180px;
}

.more-cases div {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;
  margin-left: -10px;
  column-gap: 70px;
}

.more-cases p {
  color: #f2f2f2;
}

@media all and (min-width: 1920px) {
  .cases-list li {
    padding-top: 47.6563%;
  }

  .card-image {
    width: 75%;
    height: 68.8525%;
  }
}

@media all and (max-width: 1279px) {
  .cases-list {
    row-gap: 40px;
  }

  .cases-list li {
    padding-top: 48.9362%;
  }
  
  .card-image {
    width: 70%;
    height: 58.696%;
  }

  .more-cases > div {
    margin-left: 0;
    column-gap: 30px;
  }

  .more-cases > div > img {
    width: 50px;
  }

  .more-cases p {
    font-size: 14px;
  }

  .more-cases > img {
    height: 120px;
  }

  .card-text {
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media all and (max-width: 1023px) {
  .cases-list {
    row-gap: var(--column-gutter);
  }

  .cases-list li {
    padding-top: 72.464%;
  }

  .cases-list li:last-child {
    padding-top: 0;
  }

  #cases h4 {
    padding-bottom: 40px;
  }

  .card-image {
    width: 75.758%;
    height: 64%;
  }

  .cases-list > li:last-child {
    flex-grow: 2;
  }

  .more-cases {
    position: relative;
    margin-top: 30px;
    height: auto;
    width: 100%;
  }
}

@media all and (max-width: 767px) {
  #cases h4 {
    padding-bottom: 30px;
  }

  .cases-list li {
    padding-top: 142.85715%;
  }

  .card-text {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .card-description {
    padding-top: 8px;
  }

  .card-image {
    width: 67.85715%;
    height: 61.25%;
  }
}
