#contacts .container {
  align-items: start;
}

#contacts h4 {
  padding-bottom: 30px;
}

#contacts h2 {
  max-width: 660px;
  padding-bottom: 64px;
}

.contacts {
  display: flex;
  justify-content: start;
  align-items: center;
}

.contact {
  margin-right: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.contact-image {
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  position: relative;
}

.contact-image img {
  width: auto;
}

.contact-image .contact-image-bg {
  width: 100%;
}

.contact-image-bg {
  position: absolute;
  left: 0;
  top: 0;
}

@media all and (max-width: 1279px) {
  #contacts h2 {
    max-width: 560px;
  }
}

@media all and (max-width: 1023px) {
  .contact {
    margin-right: 60px;
  }

  #contacts h2 {
    font-size: 64px;
    line-height: 1.3em;
  }

  #contacts h2,
  #contacts h4 {
    padding-bottom: 20px;
  }

  #contacts p {
    font-size: 14px;
  }
}

@media all and (max-width: 767px) {
  #contacts .container {
    align-items: center;
  }

  #contacts h2 {
    line-height: 1.3em;
    padding-bottom: 30px;
    text-align: center;
    font-size: 48px;
  }

  #contacts h4 {
    padding-bottom: 20px;
  }

  .contacts {
    flex-direction: column;
    align-items: flex-end;
    align-self: flex-end;
    gap: 20px;
  }

  .contact {
    margin-right: 0;
  }
}
