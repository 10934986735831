/* Fonts */
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
/* Import from other files */
@import "./common/global.css";
@import "./common/header.css";
@import "./main/hello.css";
@import "./main/services.css";
@import "./main/cases.css";
@import "./main/process.css";
@import "./common/contacts.css";
@import "./common/footer.css";
