footer {
  color: #2c2c2c;
}

footer .container {
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  padding-top: 30px;
  box-sizing: border-box;
  border-top: solid 1px #2c2c2c;
  padding-bottom: 75px;
}

footer .socials {
  display: flex;
}

footer .socials > * {
  margin-left: 50px;
}

@media all and (max-width: 1279px) {
  footer .container {
    padding-bottom: 50px;
  }
}

@media all and (max-width: 1023px) {
  footer {
    font-size: 14px;
  }
}

@media all and (max-width: 767px) {
  footer .container {
    flex-direction: column-reverse;
    padding-top: 20px;
    padding-bottom: 22px;
    height: auto;
    gap: 20px;
    align-items: center;
  }

  footer .socials {
    width: 100%;
    justify-content: space-between;
  }

  footer .socials > * {
    margin-left: 0;
  }
}