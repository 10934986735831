/* Header */

header {
  --line-color: #70777e;
  --menu-color: #8b9095;
  --header-height: 80px;
  position: absolute;
  width: 100%;
}

.no-scroll-desktop header {
  width: calc(100% - 17px);
}

.light {
  --line-color: #e8e6e6;
  --menu-color: #e8e6e6;
}

header .container {
  height: var(--header-height);
  flex-direction: row;
  border-bottom: solid 1px var(--line-color);
  box-sizing: border-box;
  position: relative;
  z-index: 3;
}

.logo {
  font-family: "Kharkiv Tone", sans-serif;
  font-size: 16px;
}

.active {
  position: relative;
  color: #fff;
}

.active::after,
.nav-list > li:hover::after {
  position: absolute;
  content: "";
  width: 4px;
  height: 4px;
  background-color: #e3a579;
  border-radius: 50%;
  margin-left: 2px;
}

.nav-list {
  display: flex;
}

.nav-list > li {
  position: relative;
  color: var(--menu-color);
  font-size: 14px;
  transition: color 0.3s;
}

.nav-list > li.active,
.nav-list > li:hover {
  color: #fff;
}

.nav-list > li:not(:last-child) {
  padding-right: 50px;
}

.hamburger,
.overlay {
  display: none;
  visibility: hidden;
}

.hamburger {
  width: 24px;
  height: 24px;
  position: relative;
  cursor: pointer;
}

.hamburger::before {
  content: "";
  position: absolute;
  background: white;
  left: 0;
  top: 7px;
  width: 100%;
  height: 2px;
  transition: all 0.3s ease-in-out;
}

.hamburger::after {
  content: "";
  position: absolute;
  background: white;
  left: 0;
  bottom: 7px;
  width: 100%;
  height: 2px;
  transition: all 0.3s ease-in-out;
}

.hamburger.cross::before {
  transform: rotate(45deg) translate3d(3px, 150%, 0) scaleX(0.7415);
  background: white;
}

.hamburger.cross::after {
  transform: rotate(-45deg) translate3d(3px, -150%, 0) scaleX(0.7415);
  background: white;
}

.overlay {
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  opacity: 0;
  pointer-events: none;
  transition: visibility 0s linear 0.5s, opacity 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.overlay.visible {
  z-index: 2;
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}

@media all and (max-width: 1024px) {
  body {
    position: relative;
  }

  header nav {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 0;
    top: var(--header-height);
    width: 100%;
    height: calc(100vh - var(--header-height));
    display: flex;
    align-items: center;
    justify-content: center;
    transition: visibility 0s linear 0.5s, opacity 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  }

  header nav.visible {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;
    z-index: 3;
  }

  header .nav-list {
    flex-direction: column;
    align-items: center;
  }

  header .nav-list > li {
    font-family: "Kharkiv Tone", sans-serif;
    font-weight: normal;
    font-size: 48px;
    line-height: 1.7em;
    background: linear-gradient(90deg, #a5c4e9 0%, #d8c5d6 50%, #e3a273 93.75%);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    color: transparent;
    display: inline-block;
  }

  header .nav-list > li:not(:last-child) {
    padding-right: 0;
  }

  .overlay {
    display: block;
  }

  .hamburger {
    display: block;
    visibility: visible;
  }
}

@media all and (max-width: 767px) {
  header nav {
    align-items: flex-end;
    justify-content: flex-end;
  }

  header .nav-list {
    align-items: flex-end;
    margin-bottom: 105px;
  }

  header .nav-list > li {
    font-size: 32px;
    line-height: 1.6em;
  }
}