/* Section "Hello" */

.hello {
  height: 100vh;
  min-height: 720px;
  display: flex;
  align-items: center;
  padding-top: 80px;
  padding-bottom: 0;
  box-sizing: border-box;
}

.no-scroll-desktop .hello {
  padding-right: 17px;
}

.hello .container {
  display: block;
  justify-content: initial;
  align-items: initial;
}

.hello img {
  width: auto;
}

.hello h1 {
  font-size: 140px;
  line-height: 1em;
  background: linear-gradient(90deg, #a5c4e9 0%, #d8c5d6 50%, #e3a273 93.75%);
  background-clip: text;
  color: transparent;
  font-weight: normal;
  display: inline-block;
}

.hello h1:not(:first-child) {
  font-size: 110px;
  line-height: 1.3em;
}

.title-first {
  margin-bottom: 26px;
  display: flex;
  align-items: center;
  gap: 88px;
}

.title-first h1 {
  margin-top: 20px;
}

.circles {
  height: 105px;
}

.title-first p {
  font-size: 16px;
  color: #f2f2f2;
  text-align: center;
  width: 8%;
}

.title-second {
  margin-bottom: 33px;
  display: flex;
  align-items: center;
}

.title-second h1 {
  padding-left: 125px;
}

.title-second img:not(:first-child) {
  margin-left: 125px;
  transform: rotate(90deg);
  padding: 0;
}

.hello .mobile-arrow {
  display: none;
}

@media all and (min-width: 1920px) {
  .hello {
    min-height: 850px;
  }

  .hello h1 {
    font-size: 180px;
  }

  .hello h1:not(:first-child) {
    font-size: 128px;
  }

  .title-second h1 {
    padding-left: 190px;
  }

  .circles {
    height: 132px;
  }
}

@media all and (max-width: 1439px) {
  .hello {
    min-height: 700px;
  }
}

@media all and (max-width: 1279px) {
  .hello {
    min-height: 660px;
  }

  .hello h1 {
    font-size: 110px;
  }

  .hello h1:not(:first-child) {
    font-size: 90px;
  }

  .title-first {
    gap: 70px;
  }

  .title-second h1 {
    padding-left: 95px;
  }

  .circles {
    height: 84px;
  }

  .title-first p {
    width: 15%;
  }

  .title-second > img:first-child {
    width: 80px;
  }
}

@media all and (max-width: 1023px) {
  .hello {
    min-height: 900px;
  }

  .hello .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .hello h1:not(:first-child) {
    font-size: 80px;
    text-align: center;
    line-height: 1.5em;
  }

  .title-first,
  .title-second {
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
  }

  .title-first {
    gap: 0;
  }

  .title-first h1 {
    line-height: 1.3em;
  }

  .title-first p {
    width: auto;
    padding-top: 18px;
    padding-bottom: 40px;
  }

  .title-first img,
  .title-second img {
    display: none;
  }

  .title-second h1 {
    padding-left: 0;
  }

  .hello .mobile-arrow {
    padding-top: 30px;
    padding-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 80px;
  }

  .mobile-arrow img {
    transform: rotate(90deg);
  }
}

@media all and (max-width: 767px) {
  .hello {
    min-height: 570px;
  }

  .hello h1:not(:first-child) {
    font-size: 48px;
  }

  .title-first h1 {
    font-size: 80px;
    line-height: 1.1em;
    padding-bottom: 19px;
  }

  .title-first p {
    display: none;
  }

  .title-second h1 {
    padding-left: 0;
  }

  .hello .mobile-arrow {
    padding-top: 32px;
    padding-bottom: 0;
    min-height: 50px;
  }

  .mobile-arrow img {
    width: 50px;
    height: 8px;
    object-fit: initial;
    transform: rotate(90deg);
  }
}
